import { createSelector, createEntityAdapter } from '@reduxjs/toolkit'
import { apiSlice } from '../api/apiSlice'

const evaluationsAdapter = createEntityAdapter({
})
const initialState = evaluationsAdapter.getInitialState({
  data: '',
  status: 'idle', // 'idle' | 'loading' | 'succeeded' | 'failed'
  error: null,
  count: 0
})
export const evaluationsApiSlice = apiSlice.injectEndpoints({
  reducerPath: 'campaignsSlice',
  endpoints: builder => ({
    getEvaluations: builder.query({
      query: (id) => `/evaluation/entity/${id}`,
      transformResponse: responseData => {
        return responseData
      },
      providesTags: ['Evaluations']
    }),
    createTransformation: builder.mutation({
      query: (data) => ({
        url: '/evaluation/transform',
        method: 'POST',
        body: data
      }),
      invalidatesTags: ['Evaluations']
    }),
    createEvaluation: builder.mutation({
      query: (data) => ({
        url: '/evaluation/create_evaluation',
        method: 'POST',
        body: data
      }),
      invalidatesTags: ['Evaluations']
    }),
    getEvaluationHistoric: builder.query({
      query: (id) => `/evaluation/historic/${id}`,
      transformResponse: responseData => {
        return responseData
      },
      providesTags: ['Evaluations']
    }),
    getEvaluationResults: builder.query({
      query: (id) => `/evaluation/results/${id}`,
      transformResponse: responseData => {
        return responseData
      },
      providesTags: ['Evaluations']
    }),
    getEvaluationChart: builder.query({
      query: (id) => `/evaluation/chart_results/${id}`,
      transformResponse: responseData => {
        return responseData
      },
      providesTags: ['Evaluations']
    }),
    getEvaluationQuestionChart: builder.query({
      query: (id) => `/evaluation/chart_results_questions/${id}`,
      transformResponse: responseData => {
        return responseData
      },
      providesTags: ['Evaluations']
    })
  })
})

export const {
  useGetEvaluationsQuery,
  useGetEvaluationResultsQuery,
  useGetEvaluationChartQuery,
  useGetEvaluationHistoricQuery,
  useCreateTransformationMutation,
  useGetEvaluationQuestionChartQuery,
  useCreateEvaluationMutation
} = evaluationsApiSlice

export const selectEvaluationsResult = evaluationsApiSlice.endpoints.getEvaluations.select()

export const selectEvaluationsData = createSelector(
  selectEvaluationsResult,
  campaignsResult => campaignsResult.data
)

export const {
  selectAll: selectAllEvaluations,
  selectById: selectSingleEvaluation
} = evaluationsAdapter.getSelectors(state => selectEvaluationsData(state) ?? initialState)
