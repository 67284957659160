import React from 'react'
import DynamicNavBar from '../../../components/Bars/DynamicNavBar'
import { generalOptions, entityOptions } from '../DynamicNavbarOptions'
import SetMembersList from '../../../features/entities/components/setMembersList'
import AddSurveys from '../../../features/entities/components/addSurveys'
import SetEntityConsultants from '../../../features/entities/components/setEntityConsultants'
import FinalizeEntity from '../../../features/entities/components/finalizeEntity'
import { useTranslation } from 'react-i18next'
import EntityInfo from '../../../features/entities/components/entityInfo'

function CreateEntity () {
  const { t } = useTranslation()

  const [entity, setEntity] = React.useState({ survey_trees: [], entity_members: [], entityInfo: { siren: '', siret: '', nic: '', adress: '', entityName: '' }, consultant: [] })
  const pageTitle = { 1: t('admin.organisation.create.title.organisation_infos'), 2: t('admin.organisation.create.title.create_organisation'), 3: t('admin.organisation.create.title.add_entity_members'), 4: t('admin.organisation.create.title.add_surveys'), 5: t('admin.organisation.create.title.set_main_customer'), 6: t('admin.organisation.create.title.finalize') }

  return (
    <div className="w-full">
      <DynamicNavBar option="navbar.generalOptions.organisations" options={generalOptions} />
      <DynamicNavBar option="navbar.common.create" options={entityOptions} />

      <div className="w-full min-h-screen flex  flex-col items-center">
        <div className='md:w-5/6  lg:w-1/2 px-2 pt-1 mt-2 pl-4 h-full mt-5'>
          <h5 className="text-4xl  font-medium text-[#444444]">{t('admin.organisation.create.title.create_organisation')}</h5>
        </div>
          <div className="bg-[#ffffff] md:w-5/6 flex flex-col lg:w-1/2 px-2 pt-1 mt-2 shadow-sm pl-4 h-full pb-14">
            <div className='py-4'>
              <h5 className="text-2xl font-medium text-[#444444]">{pageTitle[1]}</h5>
              <EntityInfo onChangeEntity={setEntity} entity={entity} />
            </div>
            <hr />
            <div className='py-4'>
              <h5 className="text-2xl font-medium text-[#444444]">{pageTitle[3]}</h5>
              <SetMembersList onChangeEntity={setEntity} entity={entity} />
            </div>
            <hr />
            <div className='py-4'>
              <h5 className="text-2xl font-medium text-[#444444]">{pageTitle[4]}</h5>
              <AddSurveys onChangeEntity={setEntity} entity={entity} />
            </div>
            <hr />
            <div className='py-4'>
              <h5 className="text-2xl font-medium text-[#444444]">{pageTitle[5]}</h5>
              <SetEntityConsultants onChangeEntity={setEntity} entity={entity} />
            </div>
            <hr />
            <div className='py-4'>
              <h5 className="text-2xl font-medium text-[#444444]">{pageTitle[6]}</h5>
              <FinalizeEntity onChangeEntity={setEntity} entity={entity} />
              <div>
              </div>
            </div>
          </div>
      </div>
    </div>
  )
}

export default CreateEntity
