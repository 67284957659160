import React from 'react'
import Slider from '@mui/material/Slider'
import BaseQuestion from '../baseQuestion'
import PropTypes from 'prop-types'

const DoubleSlideBar = ({ displayComments, disabled, saveResponse, fullResponse, reponse, question, comment, commentUser, showComments, questionHelpText }) => {
  const [options, setOptions] = React.useState()
  const [marks, setMarks] = React.useState()
  const [value, setValue] = React.useState()
  const [step, setStep] = React.useState()
  const [reponseFinal, setReponseFinal] = React.useState()
  const [sliderColor, setSliderColor] = React.useState({ impact: '', risk: '' })
  const [questionDetails, setQuestionDetails] = React.useState()

  React.useEffect(() => {
    if (question?.question_uuid) {
      setQuestionDetails(question)
    }
  }, [question])

  React.useEffect(() => {
    setOptions(question.option)
  }, [question])
  React.useEffect(() => {
    if (reponse) {
      setReponseFinal(reponse)
    }
    if (options) {
      const long = options.length
      setStep(100 / (long * 20))
      if (long >= 1) {
        const list = options.map((op, index) => {
          if (op) {
            const step = (100 - 10) / (long - 1)
            const mark = {
              value: 10 + (index * step),
              label: index + 1,
              text: op.text ? op.text : ''
            }
            return mark
          }
        })
        setMarks(list)
      }
    }
  }, [options, reponse])

  const handleSliderChange = (e, type) => {
    const step = (100 - 10) / (options.length - 1)
    const ch = (e.target.value - 10 + step) / step
    const Index = Number(ch.toFixed(4).slice(0, -2))
    const choiceIndex = (Math.round(ch * 10) / 10)
    const pourcentage = Math.floor((Index - Math.floor(Index)) * 100)
    const clearIndex = Math.floor(Index)
    const option = options[clearIndex - 1]?.text ? options[clearIndex - 1].text : null
    const nOption = options[clearIndex]?.text ? options[clearIndex].text : null
    setReponseFinal({ ...reponse, [type]: { value: e.target.value, choiceIndex, option, nextOption: nOption, pourcentage, question: question.uuid } })
  }

  const sendData = (state) => {
    const response = {
      complexValue: state !== 'delete' ? { ...reponseFinal } : {},
      question: question.uuid,
      state
    }
    saveResponse(response)
  }
  React.useState(() => {
    if (reponse.value) {
      setValue(reponse.value)
    }
  }, [reponse.value])

  React.useEffect(() => {
    if (reponseFinal) {
      if (reponseFinal?.impact?.choiceIndex >= 1) {
        setSliderColor({ impact: '#3096d1' })
      } else {
        setSliderColor({ impact: '#de6868' })
      }
      if (reponseFinal?.risk?.choiceIndex >= 1) {
        setSliderColor({ risk: '#3096d1' })
      } else {
        setSliderColor({ risk: '#de6868' })
      }
    }
  }, [reponseFinal])
  return (
    <BaseQuestion displayComments={displayComments} disabled={disabled} sendData={(state) => sendData(state)} questionHelpText={questionHelpText}
      reponse={fullResponse} showComments={showComments} commentUser={commentUser} comment={comment} question={question}>

      <h5 className="text-lg pb-2 px-2">{question.text}</h5>
      <div className='w-full pl-4 flex gap-5'>
        <div className='w-2/3'>
          <div>
            <h5 className="text-lg">Impact</h5>
            <Slider
              disabled={disabled || new Set(['ignored', 'validated']).has(fullResponse?.state)}
              aria-label="Custom marks"
              value={reponseFinal?.impact?.value ? reponseFinal.impact.value : 0}
              onChange={(e) => handleSliderChange(e, 'impact')}
              onChangeCommitted={() => sendData('draft')}
              valueLabelFormat={reponseFinal?.impact?.choiceIndex ? reponseFinal.impact.choiceIndex : 0}
              step={step}
              marks={marks}
              sx={{ color: sliderColor.impact, '& .MuiSlider-rail': { color: sliderColor.impact } }}
              valueLabelDisplay="auto"
            />
          </div>
          <div>
            <h5 className="text-lg">Urgence</h5>
            <Slider
              disabled={disabled || new Set(['ignored', 'validated']).has(fullResponse?.state)}
              aria-label="Custom marks"
              value={reponseFinal?.risk?.value ? reponseFinal.risk.value : 0}
              onChange={(e) => handleSliderChange(e, 'risk')}
              onChangeCommitted={() => sendData('draft')}
              valueLabelFormat={reponseFinal?.risk?.choiceIndex ? reponseFinal.risk.choiceIndex : 0}
              step={step}
              marks={marks}
              sx={{ color: sliderColor.risk, '& .MuiSlider-rail': { color: sliderColor.risk } }}
              valueLabelDisplay="auto"
            />
          </div>
        </div>
        <div className='flex flex-col gap-1'>
          {marks
            ? marks.map((op, index) => {
              if (op?.label !== 0) {
                return (
                  <div className='flex gap-2' key={index}>
                    <span className='px-1 bg-gray-300 rounded-sm max-h-fit'>{op?.label ? op.label : index + 1}</span>
                    <p className='text-gray-800 text-md'>{op.text ? op.text : undefined}</p>
                  </div>)
              }
            }
            )
            : undefined
          }
          <div />
        </div>
      </div>
    </BaseQuestion>
  )
}
DoubleSlideBar.propTypes = {
  text: PropTypes.string,
  displayComments: PropTypes.bool,
  disabled: PropTypes.bool.isRequired,
  responses: PropTypes.array,
  commentUser: PropTypes.string,
  saveResponse: PropTypes.func,
  reponse: PropTypes.string,
  question: PropTypes.object.isRequired,
  comment: PropTypes.object,
  questionHelpText: PropTypes.string,
  showComments: PropTypes.bool,
  fullResponse: PropTypes.object

}
export default DoubleSlideBar


