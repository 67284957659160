import React from 'react'
import { useTranslation } from 'react-i18next'
import { useGetActivitiesQuery, selectAllActivities } from '../../../activitiesSector/activitiesSlice'
import { useSelector } from 'react-redux'

function ModifyGeneralInfo ({ entity, changeEntity }) {
  const handleChange = event => {
    const min = 0
    const max = 999999
    const value = Math.max(min, Math.min(max, Number(event.target.value)))
    changeEntity(entity = { ...entity, employee_Number: value })
  }
  const { data } = useGetActivitiesQuery()
  const activivitySectors = useSelector(selectAllActivities)
  const { t } = useTranslation()
  return (
    <>
    <div className="md:w-1/2 w-4/5 px-3 md:mb-0 mt-1 relative rounded-sm ">
    <label
        htmlFor="price"
        className="block text-md font-medium text-gray-700">
        {t('admin.organisation.edit.organisationName')}
      </label>
      <input type="text" name="price" id="price"
        className="focus:ring-teal-500
      focus:border-teal-500 block  w-full pl-2 pr-12 text-lg  text-[#444444]
      rounded-sm" disabled={true}
        style={{ border: '1px solid #e8e6e6' }}
        value={entity?.name ? entity.name : ''}/>
    </div>
    <div className="md:w-1/2 w-4/5 px-3 mb-6 md:mb-0 mt-1 relative rounded-sm ">
    <label
        htmlFor="employee_number"
        className="block text-md font-medium text-gray-700">
          {t('admin.organisation.edit.nbr_employee')}
      </label>
      <input type="number" name="employee_number" id="employee_number"
      style={{ border: '1px solid #e8e6e6' }}
      onKeyPress={(event) => {
        if (!/[0-9]/.test(event.key)) {
          event.preventDefault()
        }
      }}
        className="focus:ring-teal-500
      focus:border-teal-500   w-full pl-2 pr-12 text-lg  text-[#444444]
      rounded-sm"
        value={entity?.employee_Number ? entity.employee_Number : ''}
        onChange={(e) => handleChange(e)}/>
    </div>
    <div className="md:w-1/2 w-4/5 px-3 mt-1 relative rounded-sm ">
      <label
        htmlFor="price" className="block text-md font-medium text-gray-700">
        {t('admin.organisation.edit.activity_sector')}
      </label>
      <select className="focus:ring-teal-500 focus:border-teal-500 block border-1 border-[#e8e6e6] w-full pl-1 pr-2 py-0.5 text-lg  text-[#444444]
      rounded-sm" value ={entity?.activity_sector ? entity.activity_sector : undefined }
    onChange={(e) => changeEntity(entity = { ...entity, activity_sector: e.target.value, entityInfo: { ...entity.entityInfo, activitysector: e.target.value } })}>
      {activivitySectors.map((sector) => <option key={sector.id} value={sector.id}>{sector.reference} - {sector.name}</option>)}
  </select>
    </div>
    </>
  )
}

export default ModifyGeneralInfo
