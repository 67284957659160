import { createSelector, createEntityAdapter } from '@reduxjs/toolkit'
import { apiSlice } from '../api/apiSlice'

const questionsAdapter = createEntityAdapter({
})
const initialState = questionsAdapter.getInitialState({
  data: '',
  status: 'idle', // 'idle' | 'loading' | 'succeeded' | 'failed'
  error: null,
  count: 0
})

export const questionApiSlice = apiSlice.injectEndpoints({
  reducerPath: 'questionsSlice',
  endpoints: builder => ({
    getQuestionsList: builder.query({
      query: () => '/question/all',
      transformResponse: responseData => {
        return responseData
      },
      providesTags: ['Question']
    }),
    getSingleQuestion: builder.query({
      query: (id) => `/question/${id}/details`,
      transformResponse: responseData => {
        return responseData
      },
      providesTags: ['Question']
    }),
    createQuestion: builder.mutation({
      query: (data) => ({
        url: '/question/create',
        method: 'POST',
        body: data
      }),
      invalidatesTags: ['Question', 'Option']
    }),
    getOptions: builder.query({
      query: () => '/question/options',
      transformResponse: responseData => {
        return responseData
      },
      providesTags: ['Option']
    }),
    getOptionsLG: builder.query({
      query: () => '/question/option/get_local_global_unsaved_question',
      transformResponse: responseData => {
        return responseData
      },
      providesTags: ['Option']
    }),
    createOption: builder.mutation({
      query: (data) => ({
        url: '/question/option/create',
        method: 'POST',
        body: data
      }),
      invalidatesTags: ['Option']
    }),
    deleteOption: builder.mutation({
      query: (id) => ({
        url: `/question/option/${id}/details`,
        method: 'DELETE'
      }),
      invalidatesTags: ['Option']
    }),
    getOptionsTemplates: builder.query({
      query: () => '/question/optionTemplates',
      transformResponse: responseData => {
        return responseData
      },
      providesTags: ['OptionTemplate']
    }),
    getOptionsTemplatesDetails: builder.query({
      query: () => '/question/optionTemplates/responses_details',
      transformResponse: responseData => {
        return responseData
      },
      providesTags: ['OptionTemplate']
    }),
    createOptionTemplate: builder.mutation({
      query: (data) => ({
        url: '/question/optionTemplate/create',
        method: 'POST',
        body: data
      }),
      invalidatesTags: ['OptionTemplate']
    }),
    getOptionsTemplatesDetail: builder.query({
      query: (id) => `/question/optionTemplate/${id}/details`,
      transformResponse: responseData => {
        return responseData
      },
      providesTags: ['OptionTemplate']
    }),
    deleteOptionTemplate: builder.mutation({
      query: (id) => ({
        url: `/question/optionTemplate/${id}/details`,
        method: 'DELETE'
      }),
      invalidatesTags: ['OptionTemplate']
    }),
    updateOptionTemplate: builder.mutation({
      query: (data) => ({
        url: `/question/optionTemplate/${data.id}/details`,
        body: data,
        method: 'PATCH'
      }),
      invalidatesTags: ['OptionTemplate']
    }),
    createTagQuestion: builder.mutation({
      query: (data) => ({
        url: '/question/tagquestion/create',
        method: 'POST',
        body: data
      }),
      invalidatesTags: ['TagQuestion']
    }),
    getQuestionTags: builder.query({
      query: () => '/question/tagquestions',
      transformResponse: responseData => {
        return responseData
      },
      providesTags: ['TagQuestion']
    }),
    deleteTagQuestion: builder.mutation({
      query: (id) => ({
        url: `/question/tagquestion/${id}/details`,
        method: 'DELETE'
      }),
      invalidatesTags: ['TagQuestion']
    }),
    modifQuestion: builder.mutation({
      query: (data) => ({
        url: `/question/${data.id}/details`,
        method: 'PATCH',
        body: data
      }),
      invalidatesTags: ['Question']
    }),
    deleteQuestion: builder.mutation({
      query: (id) => ({
        url: `/question/${id}/details`,
        method: 'DELETE'
      }),
      invalidatesTags: ['Question']
    }),
    importExcel: builder.mutation({
      query: (data) => ({
        url: '/question/excel/import',
        method: 'POST',
        body: data
      }),
      invalidatesTags: ['Question', 'Response']
    }),
    getChoicesList: builder.query({
      query: () => '/question/measurments/list',
      transformResponse: responseData => {
        return responseData
      }
    })
  })
})

export const {
  useGetSingleQuestionQuery,
  useCreateOptionTemplateMutation,
  useGetQuestionsListQuery,
  useGetChoicesListQuery,
  useGetQuestionTagsQuery,
  useGetOptionsTemplatesQuery,
  useCreateQuestionMutation,
  useCreateOptionMutation,
  useCreateTagQuestionMutation,
  useDeleteTagQuestionMutation,
  useGetOptionsTemplatesDetailQuery,
  useModifQuestionMutation,
  useDeleteQuestionMutation,
  useDeleteOptionMutation,
  useDeleteOptionTemplateMutation,
  useUpdateOptionTemplateMutation,
  useGetOptionsQuery,
  useGetOptionsLGQuery,
  useImportExcelMutation,
  useGetOptionsTemplatesDetailsQuery
} = questionApiSlice

export const selectQuestionsResult = questionApiSlice.endpoints.getQuestionsList.select()

export const selectQuestionsData = createSelector(
  selectQuestionsResult,
  usersResult => usersResult.data
)

export const {
  selectAll: selectAllQuestions,
  selectById: selectSingleQuestions
} = questionsAdapter.getSelectors(state => selectQuestionsData(state) ?? initialState)
